import React from 'react';
import { Layout, Section, Button, ThemeToggle, Icon } from 'components';
import './thank-you.scss';

const ThankYou = () => (
  <ThemeToggle theme="dark-mode">
    <Layout>
      <Section className="thank-you-page">
        <Icon iconText="Howdy Man" />
        <h2 className="thank-you-page-title">Thanks, partner!</h2>
        <p className="thank-you-page-content">We've received your submission and will be in touch as soon as we can!</p>
        <Button className="button" to="/">
          Back to home
        </Button>
      </Section>
    </Layout>
  </ThemeToggle>
);
export default ThankYou;
